import { NewYearOrganism } from '../organisms/NewYearOrganism.jsx';

function NewYearTemplate() {
  return (
    <>
      <NewYearOrganism />
    </>
  );
}

export { NewYearTemplate };
