function Page404ContentAtom() {
  return (
    <p>
      Ups! Wygląda na to, że trafiłeś w niezbadane rejony internetu. Może
      zatraciłeś się trochę po drodze jak po kilku lampkach dobrego wina? Nie
      martw się, wróć do głównej strony i kontynuujmy smakowanie!
    </p>
  );
}

export { Page404ContentAtom };
