import { NewYearHeaderMolecule } from '../molecules/NewYearHeaderMolecule/NewYearHeaderMolecule.jsx';

function NewYearOrganism() {
  return (
    <>
      <NewYearHeaderMolecule />
    </>
  );
}

export { NewYearOrganism };
